import { BaseModel } from './base.model';

export class OrderItemModel extends BaseModel<any> {
  get id() {
    return this._data.id;
  }

  get order() {
    return this._data.order;
  }

  get itemId() {
    return this.id;
  }

  get photo() {
    return this._data.productImage;
  }

  get title() {
    return this._data.productName;
  }

  get productName() {
    return this._data.productName;
  }

  get fulfilled() {
    if (!this.order.fulfillments) {
      return false;
    }

    return this.order.fulfillments.some((fulfillment: any) => fulfillment.includes(this.id));
  }

  get fulfilledQuantity() {
    if (!this.order.fulfillments) {
      return false;
    }

    let quantity = 0;
    for (const item of this.order.fulfillments) {
      const fulfilledItem = item.products.find(product => product.sourceItemId === this.sourceItemId);
      if (fulfilledItem) {
        quantity += fulfilledItem.quantity;
      }
    }

    return quantity;
  }

  get description() {
    return '';
  }

  get quantity() {
    return this._data.quantity;
  }

  get refundedQuantity() {
    return this._data.refundedQuantity;
  }

  get validQuantity() {
    return this.quantity;
  }

  get priceAfterDiscounts() {
    const price = this.price - this.discount;

    return +price.toFixed(2);
  }

  get currency() {
    return this._data.currency;
  }

  get selectedOptions() {
    return this._data.selectedOptions;
  }

  get price() {
    return this._data.price;
  }

  get discount() {
    return this._data.discount;
  }

  get tax() {
    return this._data.tax;
  }

  get sourceVariantId() {
    return this._data.sourceVariantId;
  }

  get sourceItemId() {
    return this._data.sourceItemId;
  }

  get sourceOrderId() {
    return this.order.sourceOrderId;
  }

  get priceBeforeDiscount() {
    return this._data.currency + ' ' + this._data.priceBeforeDiscount;
  }

  get tags() {
    return [];
  }

  get sku() {
    return this._data.sku;
  }
}
